import React from 'react';
import Home from './components/Home.js';
import './index.css'
import './components/Home.css';

function App() {
  return (
    <div className="app">
      <Home />
    </div>
  );
}

export default App;
