import React, { useEffect, useState } from 'react'
import './Navbar.css'
import Logo from '../assets/netflix-logo-vector.svg'
import Avatar from '../assets/avatar.png'

const Navbar = () => {
 const [showNav, setShowNav] = useState(false)

 const toggleNav = () => {
    if(window.scrollY > 100){
        setShowNav(false)
    } else {
        setShowNav(true)
    }
 } 

 useEffect(() => {
    window.addEventListener("scroll", toggleNav);
    return () => window.removeEventListener("scroll", toggleNav)
 }, [])

  return (
    <div className ={`nav ${showNav && 'navBlack'}`}>
        <div className="navContents">
        <img src={Logo} alt='netflix logo' className='navLogo' />
        <img src={Avatar} alt='avatar' className='navAvatar' />
      </div>
    </div>
  )
}

export default Navbar
