
import React, { useEffect, useState } from 'react'
import './Hero.css'
import axios from '../axios'
import requests from './Requests.js'

const Hero = () => {

  const [movie, setMovie] = useState([])

  useEffect(() => {
    async function fetchData(){
      const request = await axios.get(requests.fetchNetflixOriginals);
      setMovie(
        request.data.results[
          Math.floor(Math.random() * request.data.results.length - 1)
        ]
      )
      return request;
    }
    fetchData();
  }, [])

  console.log(movie)
  const imgpath = movie.backdrop_path;
  console.log('poster '+ imgpath);

  function truncate(string, n){
    return string?.length > n ? string.substr(0, n - 1) + ' . . .' : string;
  }

  return (
    <header 
    className='hero'
    style={{
      backgroundSize: "cover",
      backgroundImage: `url('https://image.tmdb.org/t/p/original/${movie?.backdrop_path}')`,
      backgroundPosition: "center center"
    }}
    >
      <div className='heroContents'>

        <h1 className='heroTitle'>
           {movie?.title || movie?.name || movie?.original_name}
          
        </h1>

          <div className='heroButtons'>
            <button className='heroButton'>Play</button>
            <button className='heroButton'>MyList</button>
          </div>

        <h2 className='heroDescription'>
          {truncate(movie?.overview)}
        </h2>

      </div>
      
      <div className='hero--fadeBottom' />
    </header>
  

   
  )
}

export default Hero
